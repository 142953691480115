import "./contact.css";
import Contactusmail from "./Contactusmail";
import { useState, useEffect } from "react";
import axios from "axios";
import Conbutton from './contactpopup'
import MediaQuery from 'react-responsive'

function Contactform() {
  const [info, setinfo] = useState({
    firstname: "",
    lastname: "",
    number: "",
    useremail: "",
    message: "",
  });



  const  clearstate=()=>
  {
    setinfo({firstname: '',  lastname: '',   number: '',   useremail: '',    message: '' })
  }

  const [Conpopup, setConpopup] = useState(false);
  useEffect(() => {
    if (Conpopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [Conpopup]);

const [firsterror, setfirsterror]= useState("");
const [lasterror, setlasterror]= useState("");
const [numbererror, setnumbererror]= useState("");
const [mailerror, setmailerror]= useState("");
const [msgerror, setmsgerror]= useState("");
 

const callapi = async (e) => {
    e.preventDefault();
     axios.post('https://www.cybrsky.com/nodeapi/contact', {
      email: info.useremail,
      subject: "Contact us",
      firstname: info.firstname,
      lastname: info.lastname,
      number: info.number,
      contactmsg: info.message,
    });
  };


  const handlesubmit =  (e) => 
  {

    let valid = true;
    e.preventDefault();
    const regName = /^[a-z ,.'-]+$/i;
    const regNum = /([1-9][0-9]*)|0/;
    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
  
    if(info.firstname.length<3)
    {
      setfirsterror("Name must contain more than two alphabets.");
      valid = false;
    }
    
    if(!regName.test(info.firstname))
    {
      setfirsterror("Invalid First Name");
      valid = false;
    }
    if(info.firstname==="")
    {
        setfirsterror("This is a required field.");
        valid = false;
    }
  
    if(info.lastname.length<3)
    {
      setlasterror("Name must contain more than two alphabets.");
      valid = false;
    }
    if(!regName.test(info.lastname))
    {
      setlasterror("Invalid Last Name");
      valid = false;
    }
    if(info.lastname==="")
    {
        setlasterror("This is a required field.");
        valid = false;
    }
    if(!regNum.test(info.number))
    {
      setnumbererror("Contact must be numbers only.");
      valid = false;
    }
    if(info.number ==="")
    {
      setnumbererror("This is a required field.");
      valid = false;
    }
  
  
  
    if (!regEx.test(info.useremail))
    {
      setmailerror("Invalid Email.");
      valid = false;
    }
    if (info.useremail==="")
    {
      setmailerror("This is a required field.");
      valid = false;
    }

    if(info.message.length<10)
    {
      setmsgerror("Message Length should be greater than 10");
      valid = false;
    }
    if(info.message==='')
    {
      setmsgerror("This is a required field.");
      valid = false;
    }
   
   if(valid)
   {
    clearstate();
    callapi(e);
    setConpopup(true);
    e.target.reset();
   }
  };

  const handlechange = (e) => {
    
    setinfo((info) => ({
      ...info,
      [e.target.name]: e.target.value,
    }));
  };
  console.log(info);

  return (
    <>
      <div className="main-container-contact-form">
        <div class="contact-form">
          <div class="first-container">
            <Contactusmail></Contactusmail>
          </div>
          <div class="second-container">
            <h2>Send Us A Message</h2>
            <form onSubmit={handlesubmit}>
              <div class="form-group">
                <label for="name-input">Tell us your name*</label>

                <input
                  onChange={handlechange}
                  name="firstname"
                  id="name-input"
                  type="text"
                  placeholder="First name"
                  onFocus = {(e)=>
                    {
                      setfirsterror("")
                    }}
                />
                                <MediaQuery maxWidth={480}>
<br />
                  </MediaQuery>
                <input
                  onChange={handlechange}
                  name="lastname"
                  type="text"
                  id="name-input-two"
                  placeholder="Last name"
                  onFocus = {(e)=>
                    {
                      setlasterror("")
                    }}
                />
              </div>
              <div className="main-wrapper-error">
              <div className="input-section-div-error-about">
                 {firsterror}
                  </div>
                  <div className="input-section-div-error-about">
                  {lasterror}
                  </div>
              </div>
              <div class="form-group">
                <label for="email-input">Enter your email*</label>
                <input
                  onChange={handlechange}
                  name="useremail"
                  id="email-input"
                  type="text"
                  placeholder="Eg. example@email.com"
                  onFocus = {(e)=>
                    {
                      setmailerror("")
                    }}
                />
              </div>
              <div className="input-section-div-error-about">
              {mailerror}
                  </div>
              <div class="form-group">
                <label for="phone-input">Enter phone number*</label>
                <input
                  onChange={handlechange}
                  name="number"
                  id="phone-input"
                  type="text"
                  placeholder="Eg. +91 1234567890"
                  onFocus = {(e)=>
                    {
                      setnumbererror("")
                    }}
                />
              </div>
              <div className="input-section-div-error-about">
                 {numbererror}
                  </div>
              <div class="form-group">
                <label for="message-textarea">Message</label>
                <textarea
                  onChange={handlechange}
                  name="message"
                  id="message-textarea"
                  placeholder="Write us a message"
                  onFocus = {(e)=>
                    {
                      setmsgerror("")
                    }}
                ></textarea>
              </div>
              <div className="input-section-div-error-about">
                 {msgerror}
                  </div>
              <input className="button" type="submit" value="Submit" />
              <Conbutton trigger={Conpopup} setTrigger={setConpopup}></Conbutton>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default Contactform;
