
export  const sliderdata = [
    {
        image:"https://images.unsplash.com/photo-1582233479366-6d38bc390a08?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2966&q=80",
        heading: "Manav Gupta",
        desc: "It’s really helped me to feel more confident in my intended career path. I have solidified what I want to be doing, and I wouldn’t have this feeling if it were not for Cybrsky."
    },
    {
        image:"https://images.unsplash.com/photo-1582233479366-6d38bc390a08?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2966&q=80",
        heading: "Aman Thakur",
        desc: "Best place for learning related to Cyber Security Courses also and the faculties way of talk and Knowledge Excellent and I would like to highly Recommended to Join Cybrsky."
    },
    {
        image:"https://images.unsplash.com/photo-1582233479366-6d38bc390a08?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2966&q=80",
        heading: "Mridul",
        desc: "I like the environment in Cybrsky, the teaching way of trainers is Best. Special Thanks to Pranav Sir for Cyber Security Training and for their Support He is very helpful and very knowledgeable person. Highly Recommend Company for Cyber Security Training."
    }

]