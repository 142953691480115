import "./submitPopup.css"
import { IoIosCloseCircleOutline } from "react-icons/io";
import Sectionicon from "./Submitlogo";
function Popup(props)
{
    
    return(props.trigger)?(
        <>

<div className="submit-popup-blue">
        <div className="submit-popup">
            <div className="div-class-popup">
                <button className="popup-close" onClick={()=>
                {
                    props.setTrigger(false)
                    window.location.reload(); 
                }}
                >
                 <IoIosCloseCircleOutline></IoIosCloseCircleOutline>
                </button>
        </div>
                {props.children}
                <div className="div-main-container-popup-submit">
                    <div className="div-main-container-popup-submit-center">
                        <Sectionicon></Sectionicon>
                    </div>
                <div>

   
    </div>
                </div>

        </div>
</div>
        </>
    ):""

}
export default Popup;