import lottie from "lottie-web";
import pentest from './contact-usmail.json';
import React, { useEffect, useRef } from "react";

 function Sectionicon()
 {

    const anime = useRef(null);
    useEffect(() => {
      lottie.loadAnimation({
        container: anime.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData:  pentest,
      });
      return () => lottie.stop();
    }, []);
    return <div className="mail-contact-lottie" style={{ transform: 'scale(1.1)' }} ref={anime}></div>;
  };


  
  export default Sectionicon;
