import sliderone from "./images/sliderone.webp"
import slidertwo from "./images/slidertwo.jpeg"
import sliderthree from "./images/sliderthree.webp"

export  const sliderdata = [
    {
        image: sliderone,
        heading: "Comprehensive Security at one place",
        desc: "Cybrsky provides on-demand pentest services which can help mitigate these risks by providing cybersecurity professionals with actionable insights they need to protect their clients. We use AI to automate the process of pen testing. It uses machine learning to identify vulnerabilities in a system and then generates an automated report that includes recommendations for remediation."
    },
    {
        image: slidertwo,
        heading: "AI Power cyber security",
        desc: "Most companies conduct pen testing on their software in order to find security vulnerabilities and prevent exploitation. Given that there are hundreds of services running on a single network and multiple different use cases, it is hard to keep track of all the vulnerabilities. That’s why we use Artificial intelligence for provide quick and reliable services to our customers."
    },
    {
        image: sliderthree,
        heading: "Secure Agile software development",
        desc: "Pen testing is not just about finding vulnerabilities but also about providing all the stakeholders with a clear idea of where your organization stands as far as compliance is concerned. CybrSky offers Secure Agile development, a simple process to analyze code, find all the possible vulnerabilities in the code and that helps developers fix security vulnerabilities easily."
    }

]