import "./navbar.css"
import { NavLink } from 'react-router-dom';
import React,{ useState } from "react";
import Backgoundlogo from './images/logotwo.webp'
import ScrollToTop from './linktotop.jsx'
import MediaQuery from 'react-responsive'

export default function Navbar()
{

    const [isActive, setActive] = useState("false");
    const [isCheck, setCheck] = useState("false");
    const [isbar, setbar] = useState("false");
    const [navbarclass, setnavbarclass] = useState(false);
    const navbarclasschanger= ()=>
    {
        if(window.scrollY>100)
        {
            setnavbarclass(true);
        }
        else
        {
            setnavbarclass(false);
        }
    }

const show=()=>
{

    setCheck(!isCheck);
    setActive(!isActive);
    setbar(!isbar);
}

    console.log(navbarclass);
    window.addEventListener("scroll", navbarclasschanger);

    
    return(
        <>

        <ScrollToTop />

        <MediaQuery minWidth={750}>
            <div style={{ backgroundColor:'white', boxShadow:"0px 0px 15px rgb(0 0 0 / 70%)" }}   className="outer-main-container-navbar">
                <div className="outer-main-container-navbar-logo-outer">
                <NavLink to="/">
                <div className="outer-main-container-navbar-logo" style={{  backgroundImage: `url(${Backgoundlogo})`}  }   /></NavLink>
                </div>
                <div className="outer-main-container-navbar-links-outer">
                    <div className="outer-main-container-navbar-links-inner">
                        <nav>
                        <NavLink style={ navbarclass ? { color:'black'}:{color: 'black',fontWeight: 600}} className={({ isActive }) => isActive? "active": 'not-active'} to="/about" ><div className="wrapper"><div className="navbar-items-links">About Us</div><span className="slider" style={ navbarclass ? { backgroundColor:'black'}:{backgroundColor: 'black'}}></span></div></NavLink>
                        <NavLink style={ navbarclass ? { color:'black'}:{color: 'black',fontWeight: 600}} className={({ isActive }) => isActive? "active": 'not-active'} to="/services" ><div className="wrapper"><div className="navbar-items-links" >Services</div><span className="slider" style={ navbarclass ? { backgroundColor:'black'}:{backgroundColor: 'black'}} ></span></div></NavLink>
                        <NavLink style={ navbarclass ? { color:'black'}:{color: 'black',fontWeight: 600}} className={({ isActive }) => isActive? "active": 'not-active'} to="/courses" ><div className="wrapper"><div className="navbar-items-links" >Our Trainings</div><span className="slider" style={ navbarclass ? { backgroundColor:'black'}:{backgroundColor: 'black'}}></span></div></NavLink>
                        </nav>
                        <NavLink style={ navbarclass ? { color:'black'}:{color: 'black',fontWeight: 600}} className={({ isActive }) => isActive? "active": 'not-active'} to="/contact" ><div className="wrapper-end"><div className="navbar-items-links-contact" >Contact Us</div><span className="slider" style={ navbarclass ? { backgroundColor:'black'}:{backgroundColor: 'black'}}></span></div></NavLink>
                       

     
                    </div>
                </div>
            </div>
            </MediaQuery>


            <MediaQuery maxWidth={480}>
            <div className="main-container-navbar">
            <NavLink to="/">
                <div className="outer-main-container-navbar-logo-ham" style={{  backgroundImage: `url(${Backgoundlogo})`}  }   /></NavLink>

                <div className="main-container-nav-hamburger">
                <div className='menu-btn'>
                <div  className={isCheck ? "check-button-close" : "check-button-open"} onClick={show}>  </div >
               </div>
                </div>
            </div>
            <div className="main-container-navbar-menu" style={isCheck ?{"height" : "00vh"}:{"height" :"35vh"} } >
            <div className="spaner" style={isCheck ?{"display" : "none"}:{"display" : "flex"} } >

                        <NavLink  className={({ isActive }) => isActive? "active-ham hamburger-links": 'not-active-ham hamburger-links'} to="/about" ><div className="wrapper"><div className="navbar-items-links-ham">About Us</div></div></NavLink>
                        <NavLink  className={({ isActive }) => isActive? "active-ham hamburger-links": 'not-active-ham hamburger-links'} to="/services" ><div className="wrapper"><div className="navbar-items-links-ham">Services</div></div></NavLink>
                        <NavLink  className={({ isActive }) => isActive? "active-ham hamburger-links": 'not-active-ham hamburger-links'} to="/courses" ><div className="wrapper"><div className="navbar-items-links-ham">Our Trainings</div></div></NavLink>
                        <NavLink  className={({ isActive }) => isActive? "active-ham hamburger-links": 'not-active-ham hamburger-links'} to="/contact" ><div className="wrapper-end wrapper-end-ham"><div className="navbar-items-links-ham">Contact Us</div></div></NavLink>

              
            </div>       
            </div>

            </MediaQuery>




        </>
    );

}