
import React from 'react';
import './index.css';
import App from './App';

import ReactDom from "react-dom";



ReactDom.render(
<App />, document.getElementById('root'));

