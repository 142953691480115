import './service.css'
import Footer from './Footer';
import Navbar from "./navbar";
import Simplesectionicon from "./Simple-sectionicon.jsx"
function Services()
{
    return(
        <>
        <Navbar />
                <div className="main-container-service">
                    <h1 className="main-container-heading">
                        Our Services
                    </h1>
                </div>
                


                <div className="main-home-help-section">
  <div className="main-home-help-section-one">
  <div className="main-home-help-section-modren-way-heading">
  Compressive, Scalable and Quick Penetration Testing
  </div>
  <div className="main-home-help-section-modren-way-text">

Pentesting is one of the critical aspects of cybersecurity that enables organizations to know and respond appropriately to attacks. However, it is a very resource-intensive task. It goes without saying that pentesting can only be done in short bursts and demands significant resources from both the attackers as well as the defenders.
 
  </div>
  </div>


</div>




<div className="main-about-us-container-facts-container">
    <div className="main-about-us-container-facts-card">
    <div className="main-about-us-container-facts-card-icon">
           <div className="main-about-us-container-facts-card-icon-frame-one"></div>
        </div>
        <div className="main-about-us-container-facts-card-heading">
            Faster Pentesting
        </div>
        <div className="main-about-us-container-facts-card-text">
        Launch pentests in days, not weeks with our intuitive SaaS platform and team of on-demand security experts.
        </div>


    </div>


    <div className="main-about-us-container-facts-card">
    <div className="main-about-us-container-facts-card-icon">
    <div className="main-about-us-container-facts-card-icon-frame-two"></div>
        </div>
        <div className="main-about-us-container-facts-card-heading">
        Remediate Risk Smarter
        </div>
        <div className="main-about-us-container-facts-card-text">
        Accelerate find-to-fix cycles through technology integrations and real-time collaboration with pentesters.
        </div>

    </div>


    <div className="main-about-us-container-facts-card">
    <div className="main-about-us-container-facts-card-icon">
    <div className="main-about-us-container-facts-card-icon-frame-three"></div>
        </div>
        <div className="main-about-us-container-facts-card-heading">
       Stronger  Security 
        </div>
        <div className="main-about-us-container-facts-card-text">
        Mature your security program through a scalable, data-driven approach to pentesting.
        </div>

    </div>
   
     
</div>





                <h3 className='main-cotnainer-heading-two'>Services We Offer</h3>

                <div className="main-container-service-section">

<div className="main-container-service-section-info">
        <div className="main-container-service-section-info-img"></div>
        <div className="main-container-service-section-info-text">
          <div className="main-container-service-section-info-text-wrapper">
          <div className="main-container-service-section-info-text-heading">
          Pentest On Demand Security Services
        </div>
        <div className="main-container-service-section-info-text-heading-line for-pentest"></div>
        <div className="main-container-service-section-info-text-info">
          <ul>
            <li>Secure your future with us in this digital world . Our proactive approach helps our clients to enhance there overall protection from cyber risks.</li>
            <br></br>
            <li>We always focus on the security of our clients and provide reliable penetration testing services with fast and accurate delivery.</li>
          </ul>
        </div>
          </div>
          <div className="main-container-service-section-info-text-wrapper">
          <div className="main-container-service-section-info-text-heading">
          Web Application Security Services
        </div>
        <div className="main-container-service-section-info-text-heading-line for-web"></div>
        <div className="main-container-service-section-info-text-info">
          <ul>
            <li>Identify the threats using advance pen-testing approach in the web applications followed by manual & automation testing techniques.</li>
            <br></br>
            <li>Our extensive and advance testing approach identify all possible attack surfaces and helps improve your overall application security.</li>
          </ul>
        </div>
          </div>

        </div>
</div>

<div className="main-container-service-section-info-2">
        <div className="main-container-service-section-info-text">
          <div className="main-container-service-section-info-text-wrapper">
          <div className="main-container-service-section-info-text-heading">
          Mobile Application Security Services
        </div>
        <div className="main-container-service-section-info-text-heading-line for-mob"></div>
        <div className="main-container-service-section-info-text-info">
          <ul>
            <li>Our Depth analysis of Android/IOS application with static and dynamic security testing approach identify security vulnerabilities and all weak aspects of code helps you to track all weakness in the mobile application.</li>
            <br></br>
            <li>Our depth and advance testing approach reduce the overall risk in your code and maintain the confidentiality of application data.</li>
          </ul>
        </div>
          </div>
          <br></br>
          <div className="main-container-service-section-info-text-wrapper">
          <div className="main-container-service-section-info-text-heading">
          Infra & Network Security Services
        </div>
        <div className="main-container-service-section-info-text-heading-line for-infra"></div>
        <div className="main-container-service-section-info-text-info">
          <ul>
            <li>Let our team helps to identify risk in your critical Infrastructure and Networks.</li>
            <br></br>
            <li>Critical Infrastructure’s are always the targets to attacker’s and our expertise team will helps identify internal and external threats to make your infra & networks secure. </li>
          </ul>
        </div>
          </div>

        </div>
        <div className="main-container-service-section-info-img-2"></div>
</div>
<div className="main-container-service-section-info">
        <div className="main-container-service-section-info-img"></div>
        <div className="main-container-service-section-info-text">
          <div className="main-container-service-section-info-text-wrapper">
          <div className="main-container-service-section-info-text-heading">
          Cloud Security Services
        </div>
        <div className="main-container-service-section-info-text-heading-line for-cloud"></div>
        <div className="main-container-service-section-info-text-info">
          <ul>
            <li>Testing to ensure the cloud hosted applications are secure against any vulnerabilities.</li>
            <br></br>
            <li>Our security audit helps to maintain security of your cloud infrastructure by identifying threats, misconfiguration of files and unauthorized access</li>
          </ul>
        </div>
          </div>
          <div className="main-container-service-section-info-text-wrapper">
          <div className="main-container-service-section-info-text-heading">
          API & End Point Security
        </div>
        <div className="main-container-service-section-info-text-heading-line for-api"></div>
        <div className="main-container-service-section-info-text-info">
          <ul>
            <li>API, attackers can gain access to your network using one kind of attack. Don’t let them to gain the access to your API data.</li>
            <br></br>
            <li>Our team helps you to provide extra layer of security to your API and web services by identify the vulnerabilities & external threats.</li>
          </ul>
        </div>
          </div>

        </div>
</div>
</div>



<div className="home-main-simple-way">
  <div className="home-main-simple-way-section-one">
    <div className="home-main-simple-way-section-one-heading">
    Why Love Our Service

    </div>
    <div className="home-main-simple-way-section-one-text">
    CybrSky is totally owned by its founders and no investors have a share in it. That means we have
full control over where we go and can completely focus on creating value for our customers. 

<br />
<br />
We continue to strongly invest in the team and stay around for the long term.

      </div>
  </div>
  <div className="home-main-simple-way-section-two">
<Simplesectionicon className="simple-section-icon-hover"></Simplesectionicon>
  </div>
</div>

<Footer />

                
        </>
    );

}
export default Services;