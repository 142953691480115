import './App.css';
import React from 'react';
import Home from "./components/Home"
import { BrowserRouter as Router,Routes, Route} from 'react-router-dom';
import ScrollToTop from './components/linktotop'
import About from "./components/About.jsx"
import Ourtraining from "./components/Ourtraining.jsx"
import Services from "./components/Services.jsx"
import Contact from "./components/Contact.jsx"
import Registerfrom from "./components/Registerform.jsx"
import Cookiepolicy from "./components/cookiepolicy";
import Privacypolicy from "./components/privacypolicy"



function App() {
  return (
    <>

            <Router>
            <ScrollToTop />
   <Routes>
            <Route exact path='/' element={<Home />}></Route>
            <Route exact path='/about' element={<About />}></Route>
            <Route exact path='/services' element={<Services />}></Route>
            <Route exact path='/courses' element={<Ourtraining />}></Route>
            <Route exact path='/contact' element={<Contact />}></Route>
            <Route exact path='courses/cybersky-register' element={<Registerfrom/>}></Route>
            <Route exact path='/cookiepolicy' element={<Cookiepolicy/>}></Route>
            <Route exact path='/privacypolicy' element={<Privacypolicy/>}></Route>
            </Routes>
      </Router>
    </>
  );
}

export default App;
