import Footer from "./Footer.jsx";
import "./contact.css";
import { MdAlternateEmail } from "react-icons/md";
import { AiOutlinePhone } from "react-icons/ai";
import Navbar from "./navbar.jsx";
import Contactform from "./Contactform.jsx";

function Contact() {


  return (
    <>
      <Navbar />
      <div className="main-contact-landing-page">
        <div className="main-contact-landing-page-title">Our Contact</div>
      </div>
      <div className="main-contact-landing-Stay-Connect-wrapper">
        <div className="main-contact-landing-Stay-Connect-wrapper-one">
          <div className="main-contact-landing-Stay-Connect">
            <div className="main-contact-landing-Stay-Connect-title">
              Stay Connect
            </div>
          </div>
          <div className="main-contact-landing-get-in-touch">Get In Touch</div>
        </div>

        <div className="main-contact-landing-Stay-Connect-wrapper-two"></div>
      </div>

      <div className="main-contact-email-wrapper">
        <div className="main-contact-email">
          <span>
            <MdAlternateEmail />
          </span>
          <span>Info@cybrsky.com</span>
        </div>

        <div className="main-contact-email">
          <span>
            <AiOutlinePhone />
          </span>
          <span>+91 99155 50233</span>
        </div>

        <div className="main-contact-email">
          <span>
            <MdAlternateEmail />
          </span>
          <span>90D Block A5 Paschim Vihar New Delhi 110063 India</span>
        </div>
      </div>
<Contactform></Contactform>
      <Footer></Footer>
    </>
  );
}
export default Contact;
