import './cookie.css'
import Navbar from "./navbar"
import Footer from './Footer';
function privacy()

{
    return(<>
        <Navbar></Navbar>
        <div className="main-container-cookie-container">
       
        <h1 className='cookie-heading-tag-h1-privacy'>Privacy Policy</h1>
                <h1 className='cookie-heading-tag-h1'>Introduction</h1>
                <p className='cookie-p-tag'>Cybrsky takes Information Security and Compliance very seriously. This document is designed to help our customers ensure that their data is handled in a manner that meets their data protection and compliance requirements and to provide full transparency and a peace of mind for Cybrsky customers that their information is in good hands.</p>
                <p className='cookie-p-tag'>Our security controls and mechanisms are based on the ISO 27001 Information Security Standard and NIST Standards, which includes programs covering - Policies and Procedures, Asset Management, Access Management, Operations Security, Business Continuity Security, People Security, Product Security, Cloud and Network Infrastructure Security, Security Compliance, Third-Party Security, Vulnerability Management, as well as Security Monitoring and Incident Response.</p>
                <h1 className='cookie-heading-tag-h1'>Compliance</h1>
                <p className='cookie-p-tag'>Cybrsky is continually evaluating security standards and certifications to determine which are most appropriate and add the most value for our customer base. Currently we are ISO27001 certified and conduct annual SOC 2 audits. Annual SSAE 18 SOC 2 Audits Cybrsky conducts annual SSAE 18 SOC 2 audits using independent external auditors and has conducted this rigorous assessment for many years. Customers and prospects who have signed nondisclosure agreements may request a copy of our most recent audit report by contacting info@cybrsky.com or through your Technical Account Manager</p>
                <p className='cookie-p-tag'>ISO 27001 Certification Cybrsky (Lithium) is ISO 27001:2013 certified, which is a global standard based on information security controls and management best practices. Certifying to the ISO 27001 standard involves a rigorous three-stage assessment conducted by independent auditors. Subsequent annual onsite audits are required to maintain the certification. Access Cybrsky(Lithium) ISO 27001 certification status here. PCI DSS 3.2.1 Certification The Cybrsky Customer Experience Insights (CXI) platform is PCI DSS 3.2.1 certified. PCI DSS is an information security standard for organizations that handle credit card data. This platform is assessed on an annual basis by a Qualified Security Assessor (QSA). GDPR and CCPA Cybrsky fully welcomes GDPR and CCPA and is here to help our customers address the GDPR and CCPA compliance through our robust privacy and security protections. We appreciate that the GDPR and CCPA require our customers, as data controllers, to engage data processors that deploy appropriate safeguards. We can confirm that Cybrsky is GDPR and CCPA compliant after evaluating our controls, policies, and processes. Cybrsky fully appreciates and recognizes the importance of GDPR and CCPA to our customers in the delivery of our services to them. For the list of our sub processors, please visit https://cybrsky.com Privacy Certifications Cybrsky participates in the TRUSTe Enterprise Privacy & Data Governance Practices Certification program. This program is designed to help businesses implement strong privacy management practices consistent with a wide range of global regulations and industry standards. Access Cybrsky TRUSTe Privacy Seal status here. © 2022 Cybrsky,LLC. All Rights Reserved.</p>
                <h1 className='cookie-heading-tag-h1'>Security Operations</h1>
                <p className='cookie-p-tag'>Proactive Monitoring Cybrsky monitors all its critical infrastructure, workstations and mobile devices on a 24x7 basis. Alerts are also set up to monitor security-related events and detect security violations from the Intrusion Detection System. Security auditing is enabled on host systems and logs are sent to a secure log collection system for retention and safe keeping. In addition to proactive alerts, security logs are monitored regularly. Analysis of logs is automated to the extent practical to detect potential issues and alert responsible personnel. Encryption Cybrsky assures that all sensitive customer data is encrypted both in transit and at rest using industry standards, TLS 1.2 protocols, AES-256 encryptions, SHA2 hashes. User passwords are using strong cryptographic one-way SHA 512-bit hash with unique salts. Cybrsky periodically evaluates encryption standards and updates the algorithms in use as necessary. Vulnerability Management In addition to security hardening and installing security patches during the controlled build process, Cybrsky has adopted a standards-based approach to vulnerability lifecycle management following these four key steps: Acquire, Assess, Manage, and Report.</p>
                <ul className='cookie-ul-tag'>
                    <li className='cookie-li-tag'> Acquire - The vulnerability data is acquired from multiple sources such as Internal Process, Customer Reports and Third Party researchers in conjunction with Open Source (NIST NVD, US-CERT and others) and Commercial feeds.</li>
                    <li className='cookie-li-tag'>Assess - The information acquired from the above step is then assessed to evaluate the risk that the vulnerability possesses and is assigned a severity level (Critical, High, Medium or Low) based on the likelihood, impact, relevance of the vulnerability. Critical and High severity vulnerability are classified as P1 and mitigation is rolled out on an urgent basis.</li>
                    <li className='cookie-li-tag'>Manage - Patching process differs based on the criticality of the vulnerability that is being addressed. For Critical and High severity patches, an out of schedule patch is issued and applied and for others patches are deployed during normal maintenance windows on a published schedule. All of the patches follow the standard patching process, the fixes are tested in the QA environment and then are applied to the production system to minimize any disruption</li>
                    <li className='cookie-li-tag'> Report - Systems and applications are monitored using manual and automated tools to report on the status of security patches. Any patches or security updates that are missing are processed using the Cybrsky Vulnerability Management Lifecycle. © 2022 Cybrsky,LLC. All Rights Reserved.</li>
                    </ul> 
                    <p className='cookie-p-tag'>Network and Infrastructure Security Cybrsky divides its system into separate networks to better protect more sensitive data and to separate public services from internal services. Customer data submitted into Cybrsky is only permitted to exist in Cybrsky production network. Administrative access to systems within the production network is limited to those engineers with a specific business need. Cybrsky platform servers are allocated to the respective security groups, characterized by specific security settings (TCP/IP level), and supplemented by individual instance-level stateful firewalls. Separate VLANs are used to split production, testing and development environments, as well as to segregate end-user and administrative traffic. All network access to the production environment is protected by a multiple network traffic filtering system in a “deny-all” mode.</p>
                    <p className='cookie-p-tag'>Consistent with our DevSecOps approach, we maintain a “configuration-as-a-code” approach for network security and firewall rules, and have alerts for any discrepancies between the approved configuration and production settings. Data Retention</p>
                  
                     <p className='cookie-p-tag'>Customer data is generally retained for the duration of the customer’s contract with Cybrsky. Exceptions to this include:</p>
                    <ul className='cookie-ul-tag'><li className='cookie-li-tag'>Cybrsky Marketing: Data imported from various social media platforms is retained for a rolling twenty four (24) months before it is automatically purged.</li>
                    <li className='cookie-li-tag'> Cybrsky Care: Data imported from various social media platforms is stored for the life of the agreement but can only be exported from the Services for a period of 18 months.</li>
                    <li className='cookie-li-tag'>Cybrsky CX Insights: ○ Data received from the Customer, but not sampled and ingested into the Services, is retained for up to thirty (30) days. ○ Data ingested into the Services, but not used by the Customer, is retained for ninety (90) days and the associated meta data is retained for the lesseer of twenty four (24) months or the life of the agreement. ○ Data ingested into the Services and used by the Customer is retained for the lesser of twenty four (24) months or the life of the agreement.</li>
                    <li className='cookie-li-tag'>Cybrsky Community: Data processed within Cybrsky Community will be retained for the life of the agreement. During and after the life of the contract Cybrsky can use aggregated and anonymized data for metrics and reporting purposes. This data does not include any personal information and does not include any information about the customer or the end user.</li></ul>
              
                <p className='cookie-p-tag'>© 2022 Cybrsky,LLC. All Rights Reserved. </p>
                <h1 className='cookie-heading-tag-h1'>Data Backup & Restoration</h1>
                <p className='cookie-p-tag'>Backups are encrypted using AES 256-bit encryption and are overwritten every ninety (90) days. Access to the backups is restricted to authorized individuals. Offsite backups are kept in a secure facility. Backups are made daily and weekly. We conduct backup restoration testing every six (6) months.</p>
                <h1 className='cookie-heading-tag-h1'>Data Destruction</h1>
                 <p className='cookie-p-tag'>Once the contract is over, if the customer wishes to have a copy of its data, we securely provide the information to the customer for:</p>
                <ol className='cookie-ol-tag'>
                    <li className='cookie-li-tag'>Cybrsky Community content, at one time and at no charge, in a machine-readable format, and at Cybrsky option, either in a single data extraction or multiple data extractions.</li>
                    <li className='cookie-li-tag'>All other Cybrsky Applications, customer may download the content itself in a comma separated value (.csv) format. Cybrsky may provide additional reasonable assistance for data extractions at Cybrsky standard Professional Services rates. The availability of Content for extraction or downloading from certain Applications may be limited to the most recent 24 months.</li>
                   </ol>
                   <p className='cookie-p-tag'>The data is made available for 30 days from the contract expiration or termination, after which time it is deleted in accordance with the above "Data Retention" Section, unless otherwise agreed to by the parties. The active databases are also dropped from the production servers as well after the data extraction is transferred to the customer. Once the media used for storage is retired it is scrubbed or destroyed using NIST SP 800-88 guidelines.</p>
                   <p className='cookie-p-tag'>Secure Application Development Cybrsky has very robust processes in place to assure that security is tightly integrated within our products.</p>
                    <p className='cookie-p-tag'>Secure Software Development Lifecycle (SDLC) Cybrsky utilizes a secure software development lifecycle that includes the following steps to prevent and/or detect security vulnerabilities from getting into our products:</p>
                   <ul className='cookie-ul-tag'>
                    <li className='cookie-li-tag'> OWASP Top 10</li>
                    <li className='cookie-li-tag'>CWE/SANS TOP 25 Most Dangerous Software Errors</li>
                    <li className='cookie-li-tag'>Security design reviews</li>
                    <li className='cookie-li-tag'>Manual security reviews of source code</li>
                    <li className='cookie-li-tag'>Automated static source code security scans</li>
                    <li className='cookie-li-tag'> Automated dynamic security scans Penetration Testing Cybrsky conducts annual third-party application penetration tests.</li>
                   </ul>
                   <p className='cookie-p-tag'>Customers and prospects who have signed non-disclosure agreements may request a copy of our most recent penetration test reports by contacting info@cybrsky.com.com or through your Technical Account Manager. © 2022 Cybrsky,LLC. All Rights Reserved.</p>



                   <h1 className='cookie-heading-tag-h1'>Security Overview</h1>
                   <p className='cookie-p-tag'>also allows existing customers to perform independent security tests against non-production application instances under certain conditions. For more details, see the Cybrsky Security Testing and Reporting Policy is available on our website at https://www.cybrsky.com. Application Security Features Cybrskyapplications have built in features to address common web application security flaws and attacks, some of which include:</p>
                   <ul className='cookie-ul-tag'><li className='cookie-li-tag'>Input validation: Inputs and outputs are checked for proper and expected input to protect against cross-site scripting and script injection attacks.</li>
                   <li className='cookie-li-tag'> Role based permissions: Our applications support a robust permissions system which allows for granular control over user access.</li>
                   <li className='cookie-li-tag'>CSRF protection: Sensitive features and form submissions are protected with secure and time sensitive cross-site request forgery (CSRF) tokens</li>
                   <li className='cookie-li-tag'>Logging: User activity is logged and monitored for potential malicious behavior</li>
                   </ul>
                   <p className='cookie-p-tag'>Open Source Software The use of Open Source Software in Cybrskyproducts undergoes a risk assessment review process to identify the security, legal risks involved before they are released as part of any product or use of it inside the organisation. The review included checking the library for security vulnerabilities, license that the library is being distributed under and intended use case.</p>
                   <p className='cookie-p-tag'>Incident Response Cybrsky has established policies and procedures (also known as runbooks) for responding to potential security incidents. All incidents are managed by Cybrsky dedicated Security Incident Response Team. Cybrsky defines the types of events that must be managed via the incident response process. Incidents are classified by severity. Incident response procedures are tested and updated at least annually. Cybrsky has provisions for customer notifications in case of a breach involving customer data.</p>
                   <p className='cookie-p-tag'>Cybrskyincident response process conforms to industry best practices. It involves the following phases: Identification, Containment, Investigation, Eradication, Recovery and Lessons Learned.
     © 2022 Cybrsky,LLC. All Rights Reserved.</p>
<ul className='cookie-ul-tag'><li className='cookie-li-tag'>Identification – Determining if an incident is or has occurred</li>
<li className='cookie-li-tag'>Containment – Preventing the spread of the incident by taking the impacted systems offline</li>
<li className='cookie-li-tag'>Investigation – Determining the extent and root cause through forensics investigations</li>
<li className='cookie-li-tag'> Eradication – Elimination of the root cause</li>
<li className='cookie-li-tag'> Recovery – Restoration of services or capacity that were disabled during containment</li>
<li className='cookie-li-tag'>Lessons learned – Review of the incident to recommend long term changes that should be made to prevent or mitigate future occurrences</li>
</ul>
<p className='cookie-p-tag'>Business Continuity and Disaster Recovery</p>
<p className='cookie-p-tag'>Cybrsky utilizes AWS as its hosting platform which gives us the ability to remain resilient globally even if one location goes down. AWS spans multiple geographic locations and availability zones.</p>
<p className='cookie-p-tag'>The hosting infrastructure utilized by Cybrsky is designed with multiple redundancies for maximum uptime. Critical systems are set up in a redundant manner to eliminate single points of failure. This includes redundant servers, load balancers and databases. Regular backups are made daily and weekly and stored offsite in a secure location for safety. The backups are encrypted using AES 256-bit encryption. Backup restore testing is conducted on an annual basis. Cybrsky Disaster Recovery Plan is updated at least annually and tested on an annual basis.</p>
 <p className='cookie-p-tag'>Risk Management Program Cybrsky maintains a risk register internally that lists of all the risks that are present to the organization as a whole either internally or externally and are categorized into Critical, High, Medium and Lows. The risk management program is a multi step process which include :</p>
<ul className='cookie-ul-tag'>
    <li className='cookie-li-tag'>Risk Identification</li>
    <li className='cookie-li-tag'> Risk Assessment</li>
    <li className='cookie-li-tag'> Risk Mitigation</li>
    <li className='cookie-li-tag'>Risk Acceptance</li>
    </ul>
    <p className='cookie-p-tag'>The risk is calculated using factors like Likelihood, Impact and Risk Rating. The risk register forms a vital part of the Cybrsky Information Security roadmap so there is continuous reduction in risk to the organization and risks are prioritized based on the residual risk that is present after corresponding compensating controls are put in place</p>
    <p className='cookie-p-tag'>© 2022 Cybrsky,LLC. All Rights Reserved.</p>

    <p className='cookie-p-tag'>Third Party Vendor Management Cybrsky engages with third party vendors to provide you services effectively, where those sub-service organizations may impact the security of Cybrsky Security Posture, we take appropriate steps to ensure our security posture is maintained by establishing contractual agreements that requires services organizations to adhere to security requirements laid out by Cybrsky and to ensure their ongoing compliance with our security requirements. To review the list of our sub-services organization, please visit https://www.cybrsky.com</p>
    <p className='cookie-p-tag'>Contact Cybrsky For security related requests please email security. Please consider using a secure communication method such as PGP or SMIME for sharing sensitive information.</p>
       </div>
        <Footer></Footer>
    </>)
}

export default privacy;