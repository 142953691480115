import './Registerfrom.css';
import Popup from "./Submitpop.jsx";
import { useState, useEffect} from 'react';
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Navbar from './navbar'

function App() {

  const [userinfo, setUserInfo] = useState({
    course: [],
  });


 const [verify, setverify] = useState(false);

  const [info, setinfo] = useState({
    firstname: "",
    lastname: "",
    number: "",
    useremail: "",
 
  });

  const [Buttonpopup, setButtonpopup] = useState(false);
  useEffect(() => {
    if (Buttonpopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [Buttonpopup]);


const [firsterror, setfirsterror]= useState("");
const [lasterror, setlasterror]= useState("");
const [numbererror, setnumbererror]= useState("");
const [mailerror, setmailerror]= useState("");
const [checkerror, setcheckerror]= useState("");

const [appear, setappear] = useState(false);
const [string, setstring] = useState("Select Our Training Program");
const [value, setvalue] = useState(0);
const tooglefunction = () => {
  if (appear) {
    setappear(false);
  } else {
    setappear(true);
  }
};

useEffect(()=>
{
  if(value===0)
  {
    setstring("Select Our Training Program");
  }
},[string,value]);


function handlecheck(e)
{
  if(value>0)
  {
    setcheckerror("");
  }
  let { val, checked } = e.target;
  val = e.target.value;
  const { course } = userinfo;
  if(checked)
  {
    setvalue(value+1);
    setstring("Course Selected");
    setUserInfo({
      course: [...course, val],
    });
  }
  else
  {
    setvalue(value-1);
    setUserInfo({
      course: course.filter((e) => e !== val),
    });
  }

}


function callapi(e)
{
    axios.post('https://www.cybrsky.com/nodeapi/mail', 
    {
    email: info.useremail,
    subject: "Student Registration",
    firstname: info.firstname,
    lastname: info.lastname,
    course: userinfo.course,
    number: info.number,
  });
  

    console.warn("Form Submitted");
  }


const validation = (e)=>
{
  let valid = true;
  e.preventDefault();
  setappear(false);
  const regName = /^[a-z ,.'-]+$/i;
  const regNum = /([1-9][0-9]*)|0/;
  const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;

  if(info.firstname.length<3)
  {
    setfirsterror("Name must contain more than two alphabets.");
    valid = false;
  }
  
  if(!regName.test(info.firstname))
  {
    setfirsterror("Invalid First Name");
    valid = false;
  }
  if(info.firstname==="")
  {
      setfirsterror("This is a required field.");
      valid = false;
  }

  if(info.lastname.length<3)
  {
    setlasterror("Name must contain more than two alphabets.");
    valid = false;
  }
  if(!regName.test(info.lastname))
  {
    setlasterror("Invalid Last Name");
    valid = false;
  }
  if(info.lastname==="")
  {
      setlasterror("This is a required field.");
      valid = false;
  }
  if(!regNum.test(info.number))
  {
    setnumbererror("Contact must be numbers only.");
    valid = false;
  }
  if(info.number ==="")
  {
    setnumbererror("This is a required field.");
    valid = false;
  }



  if (!regEx.test(info.useremail))
  {
    setmailerror("Invalid Email.");
    valid = false;
  }
  if (info.useremail==="")
  {
    setmailerror("This is a required field.");
    valid = false;
  }
  if(value===0)
  {
    setcheckerror("Please Select a couse");
    valid = false;
  }

 if(valid)
 {
  callapi(e);
  e.target.reset();
  setvalue(0);
  setButtonpopup(true);
 }
}




const handlechange = (e) => {
  setinfo((info) => ({
    ...info,
    [e.target.name]: e.target.value,
  }));
};

  
  return (
    <>
    <Navbar></Navbar>
    <form onSubmit={validation}>
      <div className="div-main-container-form-submisstion">
            <div className="div-main-container-form-submisstion-img">

            </div>
            <div className="div-main-container-form-submisstion-text">
            <h3 className="main-register-form-container-section-two-heading">Let's Get You Enrolled!</h3>
            <div className="main-input-container-section">
                <div className="input-section-div">
                <label className="register-label" htmlFor="first-name">
                    First Name
                  </label>
                  <input
                   onChange={handlechange}
                    name="firstname"
                    className="register-name"
                    id="first-name"
                    type="text"
                    placeholder="First name"
                    onFocus = {(e)=>
                      {
                        setfirsterror("")
                        setappear(false);
                        
                      }}
                  />
                 
                  <div className="input-section-div-error">
                 {firsterror}
                  </div>
                </div>

                <div className="input-section-div">
                <label className="register-label" htmlFor="last-name">
                    Last Name
                  </label>
                  <input
                   onChange={handlechange}
                    name="lastname"
                    className="register-name"
                    type="text"
                    id="last-name"
                    placeholder="Last name"
                    onFocus = {(e)=>
                      {
                        setlasterror("")
                        setappear(false);
                      }}
                    
                  />
                 
                  <div className="input-section-div-error">
                 {lasterror}
                  </div>
                </div>
            </div>

            <div className="main-input-container-section">
                <div className="input-section-div">
                <label className="register-label" htmlFor="first-name">
                    Email
                  </label>
                  <input
                   onChange={handlechange}
                    name="useremail"
                    className="register-name"
                    id="first-name"
                    type="text"
                    placeholder="Email"
                    onFocus = {(e)=>
                      {
                        setappear(false);
                        setmailerror("")

                      }}
              
                  />
                 
                  <div className="input-section-div-error">
                 {mailerror}
                  </div>
                </div>

                <div className="input-section-div">
                <label className="register-label" htmlFor="last-name">
                   Contact
                  </label>
                  <input
                   onChange={handlechange}
                    name="number"
                    className="register-name"
                    type="text"
                    id="last-name"
                    placeholder="Contact"
                    onFocus = {(e)=>
                      {
                        setnumbererror("")
                        setappear(false);
                      }}
                  />
                 
                  <div className="input-section-div-error">
                 {numbererror}
                  </div>
                </div>
            </div>

<div className='wrapper-select-for-course'>
            <label className="register-label-option" onClick={tooglefunction}>
              <span name="course">{value===0 ? "" : value} {string}</span>

              <span>&#8597;</span>
            </label>
            </div>

            <div className="input-section-div-error">{value!==0 ? "" : checkerror}</div>
         
<div className="input-radio-wrapper-for-flex">


            <div
              className={
                appear ? "input-radio-wrapper" : "input-radio-wrapper-one"
              }
            >
              <span
                className="input-span optioncourse option1"
              >
                <input
                  className="checked"
                  type="checkbox"
                  id="course-one"
                  name="course"
                  onChange={e => handlecheck(e)}
                  value="CEH (Certified Ethical Hacker)"
                  
                />
                <label className="checked-in option1" htmlFor="course-one">
                  CEH (Certified Ethical Hacker)
                </label>
              </span>

              <span
                className="input-span optioncourse"
              >
                <input
                  className="checked"
                  type="checkbox"
                  id="course-two"
                  name="course"
                  onChange={e => handlecheck(e)}
                  value="Web Application Security"
                />
                <label className="checked-in" htmlFor="course-two">
                  Web Application Security
                </label>
              </span>
              <span
                className="input-span optioncourse"
              >
                <input
                  className="checked"
                  type="checkbox"
                  id="course-three"
                  name="course"
                  onChange={e => handlecheck(e)}
                  value="Mobile Application Security"
                />
                <label className="checked-in" htmlFor="course-three">
                  Mobile Application Security
                </label>
              </span>

              <span
                className="input-span optioncourse"
              >
                <input
                  className="checked"
                  type="checkbox"
                  id="course-four"
                  name="course"
                  onChange={e => handlecheck(e)}
                  value="Infra & Network Security"
                />
                <label className="checked-in" htmlFor="course-four">
                  Infra & Network Security
                </label>
              </span>

              <span
                className="input-span optioncourse  option5"
              >
                <input
                  className="checked"
                  type="checkbox"
                  id="course-five"
                  name="course"
                  onChange={e => handlecheck(e)}
                  value="Cloud Security"
                />
                <label className="checked-in option5" htmlFor="course-five">
                  Cloud Security
                </label>
              </span>
            </div>
            </div>


            <div className="wrapper-submit"
                        onClick={
                          ()=>
                          {
                            setappear(false)
                          }
                        }>     
            <ReCAPTCHA 

            sitekey="6LfS4BghAAAAAM50sCKRPh3VaMKSeO16BzwBgoxP"
            className='main-register-captcha'
            onChange={()=>
              
    {
      setverify(true)
    }}
  />
    </div>

<div className="wrapper-submit">
  
  <input
                  
                  disabled={!verify}
                  className={verify ? "main-register-submit" : "main-register-submit opacitychange"}
                  type="submit"
                  value="Submit"
                />
  </div>
  
                  <Popup trigger={Buttonpopup} setTrigger={setButtonpopup}></Popup>
                  
            </div>
            

      </div>

      </form>
    </>
  );
}

export default App;
