import lottie from "lottie-web";
import simpleicon from './simplesectionicon.json';
import React, { useEffect, useRef } from "react";

export default function Sectionicon(){

    const anime = useRef(null);
    useEffect(() => {
      lottie.loadAnimation({
        container: anime.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData:  simpleicon,
      });
      return () => lottie.stop();
    }, []);
    return <div style={{ height: 600, width: 650 }} ref={anime}></div>;
  };
  

  