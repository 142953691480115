import React from "react"
import { useState } from "react"
import "./Testimonial.css"
import {sliderdata} from "./testimonialdata.js"
import { useEffect } from "react"
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";



const Testimonial = () =>
{
    const [Currentslide, setCurrentslide] = useState(0);
    const slidelength = sliderdata.length;
    const autoslide= true;

    const prevslide=()=>
    {
        setCurrentslide(Currentslide === 0 ? 2 : Currentslide-1);
    };


    const nextslide=()=>
    {
        setCurrentslide(Currentslide === slidelength -1 ? 0 : Currentslide+1);
    };
    const slideone=()=>
    {
        setCurrentslide(Currentslide === slidelength -1 ? 0 : 0);
    };
    const slidetwo=()=>
    {
        setCurrentslide(Currentslide === slidelength -1 ? 0 : 1);
    };
    const slidethree=()=>
    {
        setCurrentslide(Currentslide === slidelength -1 ? 0 : 2);
    };


   let intervaltime = 5000;

   let slideinterval;


    function autoslidefun()
    {
     slideinterval = setInterval(nextslide, intervaltime);
     
    }

    useEffect(()=>
    {
        setCurrentslide(0);
    },[])
    useEffect(()=>
    {
        if(autoslide === true)
        {
            autoslidefun();
            return() => clearInterval(slideinterval);
        }
    },)



    return(
        <>
        <div className="outer-container-div-react-testimonialstudent">
            <div className="main-react-testimonial-heading-student">Testimonials</div>
        <div className="div-react-testimonial">
           <div className="div-testimonial-arrow student-testimonial-arrow" onClick={prevslide}><BsArrowLeftShort></BsArrowLeftShort></div>
            {sliderdata.map((slide,index)=>
            {
                return (
                    <>
                    <div className="main-testimonial-view-container">

             
                        <div className={index === Currentslide ? "react-current-testimonial" : "react-testimonial"}  key={index}>
                                {index === Currentslide && 
                                (<>
                                <div className="div-testimonial-container-flex student-testimonial">
                
                                <p className="testimonial-text color-student" >{slide.desc}</p>
                                <div className="student-testimonial-heading color-student">
                                  
                                  {slide.heading}
                              </div>
                                </div>
                        
                                </>
                                
                                )}
                        </div>
                        </div>
                 
                    </>
                )
            })}
        <div className="div-testimonial-arrow student-testimonial-arrow" onClick={nextslide}><BsArrowRightShort></BsArrowRightShort></div>
        </div>
        <div className="div-testimonial-slider">
            <span className={Currentslide === 0 ? "div-testimonial-slider-dot-current student-dot-current" : "div-testimonial-slider-dot student-dot "} onClick={slideone}></span>
            <span className={Currentslide === 1 ? "div-testimonial-slider-dot-current student-dot-current" : "div-testimonial-slider-dot student-dot"} onClick={slidetwo}></span>
            <span className={Currentslide === 2 ? "div-testimonial-slider-dot-current student-dot-current" : "div-testimonial-slider-dot student-dot"} onClick={slidethree}></span>
        </div>
        </div>
        </>
    )
}

export default Testimonial;