import "./About.css";
import Homelandingimg from "./Aboutuslanding";
import Testimonial from "./Testimonial";
import Footer from "./Footer";
import { AiOutlineCoffee } from "react-icons/ai";
import Ticker from "./Countup";
import { BsCodeSlash, BsPersonCheck, BsHourglassSplit } from "react-icons/bs";
import { BsPlay } from "react-icons/bs";
import Navbar from "./navbar";
import Contactform from "./Contactform";

function About() {

  return (
    <>
      <Navbar />
      <div className="div-home-main-container-part-one-main">
        <div className="div-home-main-container-part-one-line-wrapper"></div>

        <div className="home-main-part-one-heading-wrapper-text-section">
          <div className="home-main-part-one-heading-wrapper">
            <div className="about-main-part-one-heading">
              <span>About Us</span>
            </div>
          </div>
          <div className="home-main-part-one-heading-wrapper-two-contact">
            <Homelandingimg></Homelandingimg>
          </div>
        </div>
      </div>

      <div className="main-about-us-container">Who We Are</div>

      <div className="main-about-us-main-container">
        <div className="main-about-us-main-container-one">
          <div className="main-about-us-main-container-one-heading">
            About us
          </div>
          <div className="main-about-us-main-container-one-text">
            We work close to the side of clients to manage cyber risk and equip
            them with perspectives and programs to accelerate business progress.
            We are an independent risk advisory firm help Customers to identify
            and mitigate there cyber risk and various threats.
          </div>
        </div>
        <div className="main-about-us-main-container-two"></div>
      </div>

      <div className="main-about-us-container-facts">What We Promise</div>

      <div className="main-about-us-container-facts-text">
        We Help you evaluate the effectiveness and efficiency of your
        cybersecurity programs, and build the cybersecurity strength, Risk
        Management, Data Protection as well as complying with regulation in a
        constantly evolving threat environment and regulatory landscape.
      </div>

      <div className="main-about-us-container-facts-container">
        <div className="main-about-us-container-facts-card">
          <div className="main-about-us-container-facts-card-icon">
            <div className="main-about-us-container-facts-card-icon-frame-one"></div>
          </div>
          <div className="main-about-us-container-facts-card-heading">
            Faster Pentesting
          </div>
          <div className="main-about-us-container-facts-card-text">
            Launch pentests in days, not weeks with our intuitive SaaS platform
            and team of on-demand security experts.
          </div>
        </div>

        <div className="main-about-us-container-facts-card">
          <div className="main-about-us-container-facts-card-icon">
            <div className="main-about-us-container-facts-card-icon-frame-two"></div>
          </div>
          <div className="main-about-us-container-facts-card-heading">
            Remediate Risk Smarter
          </div>
          <div className="main-about-us-container-facts-card-text">
            Accelerate find-to-fix cycles through technology integrations and
            real-time collaboration with pentesters.
          </div>
        </div>

        <div className="main-about-us-container-facts-card">
          <div className="main-about-us-container-facts-card-icon">
            <div className="main-about-us-container-facts-card-icon-frame-three"></div>
          </div>
          <div className="main-about-us-container-facts-card-heading">
            Stronger Security
          </div>
          <div className="main-about-us-container-facts-card-text">
            Mature your security program through a scalable, data-driven
            approach to pentesting.
          </div>
        </div>
      </div>

      <div className="main-container-our-mission-and-vission">
        <div className="main-container-our-mission-and-vission-img">
          We’re a highly collaborative and supportive team, coming together on
          every project to ensure our clients get the very best results.
        </div>
        <div className="main-container-our-mission-and-vission-text">
          <div className="main-container-our-mission-wrapper">
            <div className="main-container-our-mission-card">
              <div className="main-container-our-mission-heading-icon-mission"></div>
              <div className="main-container-our-mission-heading">
                Our Mission
              </div>
              <div className="main-container-our-mission-line"></div>

              <div className="main-container-our-mission-text">
                Our mission is to identify risks and mitigate these risks and
                make our customer vulnerable free in this digital world. Improve
                the overall security and privacy posture of data and information
                systems.
              </div>
            </div>

            <div className="main-container-our-values-card">
              <div className="main-container-our-mission-heading-icon-value"></div>
              <div className="main-container-our-mission-heading">
                Our Values
              </div>
              <div className="main-container-our-mission-line"></div>

              <div className="main-container-our-mission-text">
                We value our people, encourage their growth towards their goals.
                We reward them & always appreciate their performance. We always
                listen to our people's thoughts and make them happen.
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="main-container-video-wrapper">
<div className="main-container-pop-up-video">
        <div className="main-container-pop-up-video-wrapper">
          <span>
            See What We Do !<span className="child2"></span>
          </span>

          <button
            className="main-container-pop-up-video-button-outer"
          >
            <BsPlay></BsPlay>
          </button>
        
        </div>
</div>

<div className="main-container-pop-up-video video-frame-youtube">
<iframe class="responsive-iframe" src="https://www.youtube.com/embed/AXgGvdMZUyk" title="YouTube video player"  allowfullscreen></iframe>
</div>

</div>



      <div className="div-main-container-countup">
        <div className="div-main-container-countup-part">
          <div className="div-main-container-countup-part-icon">
            <BsHourglassSplit></BsHourglassSplit>
          </div>
          <div className="div-main-container-countup-part-count">
            <Ticker className="count" end={150} duration={3}></Ticker>
          </div>
          <div className="div-main-container-countup-part-text">
            Spent Hours
          </div>
        </div>

        <div className="div-main-container-countup-part">
          <div className="div-main-container-countup-part-icon">
            <AiOutlineCoffee></AiOutlineCoffee>
          </div>
          <div className="div-main-container-countup-part-count">
            <Ticker className="count" end={3000} duration={3}></Ticker>
          </div>
          <div className="div-main-container-countup-part-text">
            Cups Of Coffee
          </div>
        </div>

        <div className="div-main-container-countup-part">
          <div className="div-main-container-countup-part-icon">
            <BsCodeSlash></BsCodeSlash>
          </div>
          <div className="div-main-container-countup-part-count">
            <Ticker className="count" end={200} duration={3}></Ticker>
          </div>
          <div className="div-main-container-countup-part-text">
            Successful Projects
          </div>
        </div>

        <div className="div-main-container-countup-part">
          <div className="div-main-container-countup-part-icon">
            <BsPersonCheck></BsPersonCheck>
          </div>
          <div className="div-main-container-countup-part-count">
            <Ticker className="count" end={214} duration={3}></Ticker>
          </div>
          <div className="div-main-container-countup-part-text">
            Happy Clients
          </div>
        </div>
      </div>

      <Testimonial />
      <Contactform />
      <Footer />
    </>
  );
}
export default About;
