import "./ourtraining.css";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
import Testimonial from "./Testimonialstudent";
import Navbar from "./navbar";

function Ourtraining() {
  return (
    <>
      <Navbar></Navbar>
      <div className="main-container-service">
                    <h1 className="main-container-heading">
                        Our Training
                    </h1>
                </div>
      <div className="main-our-training-landingpage-three-section">
        <div className="main-our-training-landingpage-section-img"></div>
        <div className="main-our-training-landingpage-section-text">
          <div className="main-our-training-landingpage-section-heading">
          Let's take a step forward<br></br> and unlock your future in 
          <br></br>cyberspace with us.

          </div>

          <div className="main-our-training-landingpage-section-card">
            <div className="main-our-training-landingpage-section-card-icon main-course-icon-one"></div>
            <div className="main-our-training-landingpage-section-card-text">
              <span>Train With Us</span>
              <span>
                In real world cyber security skillset required to build strong
                deep depth knowledge about real time skills and practice. We
                will help to become a certified security
                professional.
              </span>
            </div>
          </div>

          <div className="main-our-training-landingpage-section-card">
            <div className="main-our-training-landingpage-section-card-icon main-course-icon-two"></div>
            <div className="main-our-training-landingpage-section-card-text">
              <span>Perks & Values</span>
              <span>
                After successfull of training, CybrSky offers to work with us on
                various opportunities and we will give chance to showcase your
                skills set so, we grow together.
              </span>
            </div>
          </div>


          <div className="main-our-training-landingpage-section-card">
            <div className="main-our-training-landingpage-section-card-icon main-course-icon-three"></div>
            <div className="main-our-training-landingpage-section-card-text">
              <span>Learn and try harder</span>
              <span>
              We help professionals to gain a proper understanding
              of Application security, cloud security, data integrity, cryptography, network security, identity
              and access management, and more.  
              </span>
            </div>
          </div>

        </div>
      </div>

      <div className="home-main-our-courses-container">
        <div className="home-main-our-courses-title">Our Finest Courses</div>
        <div className="home-main-our-courses-section">
          <div className="home-main-our-courses-section-part">
            <div className="home-our-courses-section-img part-ceh"></div>

            <div className="home-main-our-courses-section-part-wrapper">
              <span>
                <div className="home-our-courses-section-wrapper-title">
                  Ethical Hacking Course
                </div>
                <div className="home-our-courses-section-wrapper-by">
                  By CybrSky
                </div>

                <div className="home-our-courses-section-wrapper-description">
                  <ul>
                    <li>This training is also ideal for professionals who are looking to make an entry into the cybersecurity domain and sharp there skill sets.</li>
                    <li>Ethical Hacking training Course is one of the globally-recognized cybersecurity courses for professionals globally. </li>
                  </ul>

                </div>
              </span>
              <div className="home-our-courses-section-wrapper-button">
                <NavLink
                  to="/courses/cybersky-register"
                  className="home-service-area-link"
                >
                  <button className="card-one-section-2-button-inner">
                    Register{" "}
                  </button>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="home-main-our-courses-section-part">
            <div className="home-our-courses-section-img part-web"></div>

            <div className="home-main-our-courses-section-part-wrapper">
              <span>
                <div className="home-our-courses-section-wrapper-title">
                  Web Application Security
                </div>
                <div className="home-our-courses-section-wrapper-by">
                  By CybrSky
                </div>

                <div className="home-our-courses-section-wrapper-description">
                  <ul>
                    <li>
                      Get hands-on experiences to gain real-world skills in web
                      application security.
                    </li>
                    <li>
                      Our practical labs gives you real time challenges and
                      learning experience.
                    </li>
                  </ul>

           
                </div>
              </span>
              <div className="home-our-courses-section-wrapper-button">
                <NavLink
                  to="/courses/cybersky-register"
                  className="home-service-area-link"
                >
                  <button className="card-one-section-2-button-inner">
                    Register{" "}
                  </button>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="home-main-our-courses-section-part">
            <div className="home-our-courses-section-img part-mobile"></div>

            <div className="home-main-our-courses-section-part-wrapper">
              <span>
                <div className="home-our-courses-section-wrapper-title">
                  Mobile Application Security
                </div>
                <div className="home-our-courses-section-wrapper-by">
                  By CybrSky
                </div>

                <div className="home-our-courses-section-wrapper-description">
                  <ul>
                    <li>
                      Learn advance vulnerability analysis techniques in mobile
                      applications platforms.
                    </li>
                    <li>
                      Learn latest techniques, and methodologies used by hackers
                      and information security professionals to exploit
                      vulnerabilities in Android/IOS applications.
                    </li>
                  </ul>

   
                </div>
              </span>
              <div className="home-our-courses-section-wrapper-button">
                <NavLink
                  to="/courses/cybersky-register"
                  className="home-service-area-link"
                >
                  <button className="card-one-section-2-button-inner">
                    Register{" "}
                  </button>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="home-main-our-courses-section-part">
            <div className="home-our-courses-section-img part-network"></div>
            <div className="home-main-our-courses-section-part-wrapper">
              <span>
                <div className="home-our-courses-section-wrapper-title">
                  Infra & Network Security
                </div>
                <div className="home-our-courses-section-wrapper-by">
                  By CybrSky
                </div>

                <div className="home-our-courses-section-wrapper-description">
                  <ul>
                    <li>
                      Learn advanced methodology of reconnaissance, enumeration
                      and gaining access to network and Infrastructure’s.
                    </li>
                  </ul>

                </div>
              </span>
              <div className="home-our-courses-section-wrapper-button">
                <NavLink
                  to="/courses/cybersky-register"
                  className="home-service-area-link"
                >
                  <button className="card-one-section-2-button-inner">
                    Register{" "}
                  </button>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="home-main-our-courses-section-part">
            <div className="home-our-courses-section-img part-cloud"></div>
            <div className="home-main-our-courses-section-part-wrapper">
              <span>
                <div className="home-our-courses-section-wrapper-title">
                  Cloud Security
                </div>
                <div className="home-our-courses-section-wrapper-by">
                  By CybrSky
                </div>

                <div className="home-our-courses-section-wrapper-description">
                  <ul>
                    <li>
                      Learn how to exploit and identify vulnerabilities in
                      applications hosted on cloud platforms like AWS and Azure.
                    </li>
                  </ul>
                </div>
              </span>
              <div className="home-our-courses-section-wrapper-button">
                <NavLink
                  to="/courses/cybersky-register"
                  className="home-service-area-link"
                >
                  <button className="card-one-section-2-button-inner">
                    Register{" "}
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Testimonial></Testimonial>
      <Footer></Footer>
    </>
  );
}
export default Ourtraining;
