import "./home.css";
import Slider from "./ReactCarousel";
import { useEffect, useState } from "react";
import React from "react";
import { NavLink } from 'react-router-dom';
import {RiDoubleQuotesL} from 'react-icons/ri';
const Footer = React.lazy(() => import('./Footer.jsx'));
const Simplesectionicon = React.lazy(() => import('./Simple-sectionicon.jsx'));
const Pentestlottie = React.lazy(() => import('./Pentestlottie'));
const Mobilelottie = React.lazy(() => import('./Homemobile'));
const Homeinfra = React.lazy(() => import('./Homeinfra'));
const Cloudhome = React.lazy(() => import('./CloudHome.jsx'));
const Apihome = React.lazy(() => import('./ApiHome.jsx'));
const Navbar = React.lazy(() => import('./navbar'));
const Websecurity = React.lazy(() => import('./websecurity.jsx'));


function Home() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [newheight, setnewheight] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  console.log(newheight);
  useEffect(() => {
    if (scrollPosition > 5770) {
      setnewheight(scrollPosition - 5770);
    }
    if (scrollPosition > 7500) {
      setnewheight(0);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  return (
    <>
          <React.Suspense fallback={<p>Loading...</p>}>
      <Navbar></Navbar>
      </React.Suspense>

      {/* This section holds the main-first-look screen of home */}

      <div className="div-home-main-container-part-one">
        <Slider></Slider>
      </div>

      {/* This section holds the service area of home */}

      <div className="home-main-about-section">
        <div className="home-main-about-section-one">
          <div className="home-main-about-section-one-heading">
            <h1 className="home-main-about-section-one-heading-text">
              Why Choose CybrSky ?
            </h1>
          </div>

          <div className="home-main-about-section-one-text">
            We work close to the side of clients to manage cyber risk and equip
            them with perspectives and programs to accelerate business progress.
            As penetration testing experts ourselves, we’ve felt the complex
            process of getting a security testing completed and then
            continuously managing the vulnerabilities, penetration testing
            reports, recurring tests, their reports, etc.
            <br /> <br />
            <br />
            <NavLink to="/about" className="home-aboutus-area-link">
              <button className="home-aboutus-area-link-button">
                Read More
              </button>
            </NavLink>
          </div>
        </div>

        <div className="home-main-about-section-two">
          <div className="home-main-about-section-two-img"></div>
        </div>
      </div>

      <div className="main-home-help-section">
        <div className="main-home-help-section-one">
          <div className="main-home-help-section-modren-way-heading">
            Compressive, Scalable and Quick Penetration Testing
          </div>
          <div className="main-home-help-section-modren-way-text">
            Pentesting is one of the critical aspects of cybersecurity that
            enables organizations to know and respond appropriately to attacks.
            However, it is a very resource-intensive task. It goes without
            saying that pentesting can only be done in short bursts and demands
            significant resources from both the attackers as well as the
            defenders.
          </div>
        </div>
      </div>

      <div className="main-about-us-container-facts-container-home"></div>

      <div className="home-main-service-section">
        <div className="home-main-service-section-heading">Services</div>

        <div className="home-main-service-section-container">
          <div className="home-main-service-section-container-one">
            <div className="home-main-service-section-card">
              <div className="home-main-service-section-card-icon">
              <React.Suspense fallback={<p>Loading...</p>}>
                <Pentestlottie></Pentestlottie>
                </React.Suspense>
              </div>
              <div className="home-main-service-section-card-heading">
                Pentest On Demand
              </div>
              <div className="home-main-service-section-card-text">
                Our proactive approach helps our clients to enhance there
                overall protection from cyber risks ....
              </div>
              <div className="home-main-service-section-card-button">
                <NavLink to="/services" className="home-aboutus-area-link">
                  <button className="main-service-section-card-button-class">
                    Read More
                  </button>
                </NavLink>
              </div>
            </div>

            <div className="home-main-service-section-card">
              <div className="home-main-service-section-card-icon">
              <React.Suspense fallback={<p>Loading...</p>}>
                <Websecurity></Websecurity>
                </React.Suspense>
              </div>
              <div className="home-main-service-section-card-heading">
                Web Application Security
              </div>
              <div className="home-main-service-section-card-text">
                Identify the threats using advance pen-testing approach in the
                web applications ....
              </div>
              <div className="home-main-service-section-card-button">
                <NavLink to="/services" className="home-aboutus-area-link">
                  <button className="main-service-section-card-button-class">
                    Read More
                  </button>
                </NavLink>
              </div>
            </div>
            <div className="home-main-service-section-card">
              <div className="home-main-service-section-card-icon">
              <React.Suspense fallback={<p>Loading...</p>}>
                <Mobilelottie></Mobilelottie>
                </React.Suspense>
              </div>
              <div className="home-main-service-section-card-heading">
                Mobile Application Security
              </div>
              <div className="home-main-service-section-card-text">
                Our Depth analysis of Android/IOS application with static and
                dynamic security testing ....
              </div>
              <div className="home-main-service-section-card-button">
                <NavLink to="/services" className="home-aboutus-area-link">
                  <button className="main-service-section-card-button-class">
                    Read More
                  </button>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="home-main-service-section-container-two">
            <div className="home-main-service-section-card">
              <div className="home-main-service-section-card-icon">
              <React.Suspense fallback={<p>Loading...</p>}>
                <Homeinfra></Homeinfra>
                </React.Suspense>
              </div>
              <div className="home-main-service-section-card-heading">
                Infra & Network Security
              </div>
              <div className="home-main-service-section-card-text">
                Critical Infrastructure’s are always the targets to attacker’s
                and our expertise team ....
              </div>
              <div className="home-main-service-section-card-button">
                <NavLink to="/services" className="home-aboutus-area-link">
                  <button className="main-service-section-card-button-class">
                    Read More
                  </button>
                </NavLink>
              </div>
            </div>
            <div className="home-main-service-section-card">
              <div className="home-main-service-section-card-icon">
              <React.Suspense fallback={<p>Loading...</p>}>
                <Cloudhome></Cloudhome>
                </React.Suspense>
              </div>
              <div className="home-main-service-section-card-heading">
                Cloud Security
              </div>
              <div className="home-main-service-section-card-text">
                Testing to ensure the cloud hosted applications are secure
                against any vulnerabilities ....
              </div>
              <div className="home-main-service-section-card-button">
                <NavLink to="/services" className="home-aboutus-area-link">
                  <button className="main-service-section-card-button-class">
                    Read More
                  </button>
                </NavLink>
              </div>
            </div>
            <div className="home-main-service-section-card">
              <div className="home-main-service-section-card-icon">
              <React.Suspense fallback={<p>Loading...</p>}>
                <Apihome></Apihome>
                </React.Suspense>
              </div>
              <div className="home-main-service-section-card-heading">
                API & End Point Security
              </div>
              <div className="home-main-service-section-card-text">
                API, attackers can gain access to your network using one kind of
                attack. Don’t let them ....
              </div>
              <div className="home-main-service-section-card-button">
                <NavLink to="/services" className="home-aboutus-area-link">
                  <button className="main-service-section-card-button-class">
                    Read More
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home-main-simple-way">
        <div className="home-main-simple-way-section-one">
          <div className="home-main-simple-way-section-one-heading">
            Why Love Our Service
          </div>
          <div className="home-main-simple-way-section-one-text">
            CybrSky is totally owned by its founders and no investors have a
            share in it. That means we have full control over where we go and
            can completely focus on creating value for our customers.
            <br />
            <br />
            We continue to strongly invest in the team and stay around for the
            long term.
          </div>
        </div>
        <div className="home-main-simple-way-section-two">
               <React.Suspense fallback={<p>Loading...</p>}>
          <Simplesectionicon className="simple-section-icon-hover"></Simplesectionicon>
          </React.Suspense>
        </div>
      </div>

      <div className="section-experience-home">
        <div className="section-experience-home-one">
          Experience the advance pentesting roadmap with us.
        </div>
        <div className="section-experience-home-two">
          <div className="main-container-wrapper-exp">
            <div className="section-experience-home-two-span-one">
              <span>50%</span>
              <span>
                Faster results and zero false positive than existing approach.
              </span>
            </div>
            <div className="section-experience-home-two-span-two">
              <span>30%</span>
              <span>
                Quick deliverables and faster remediation than existing
                approach.
              </span>
            </div>
          </div>

          <div className="main-container-wrapper-exp-one">
            <div className="section-experience-home-two-span-three">
              <span>20%</span>
              <span>
                More optimized end-to-end advance pentest approach with dynamic
                InfoSec team than existing approach.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="home-how-it-works">
        <div className="home-how-it-works-heading">How We Work</div>

        <div className="home-how-it-works-container"></div>

        <div className="home-how-it-works-detail-container">
          <div className="home-how-it-works-detail-container-section">
            <h1 className="home-how-it-works-detail-container-section-heading">
              Planning and Preparation
            </h1>
            <p className="home-how-it-works-detail-container-section-heading-para">
              A cyber security strategy offers a clear, detailed plan that
              standardizes security across the organization.
            </p>
          </div>

          <div className="home-how-it-works-detail-container-section2">
            <h1 className="home-how-it-works-detail-container-section-heading">
              Scanning and Assessment
            </h1>
            <p className="home-how-it-works-detail-container-section-heading-para">
              Scanning and Assessment is a systematic process of finding
              security loopholes in any system addressing the potential
              vulnerabilities.
            </p>
          </div>

          <div className="home-how-it-works-detail-container-section">
            <h1 className="home-how-it-works-detail-container-section-heading">
              Exploitation and Data Exfiltration
            </h1>
            <p className="home-how-it-works-detail-container-section-heading-para">
              Data exfiltration is focused on detecting the different variations
              of data exfiltration.
            </p>
          </div>

          <div className="home-how-it-works-detail-container-section">
            <h1 className="home-how-it-works-detail-container-section-heading">
              Reporting and Remediation
            </h1>
            <p className="home-how-it-works-detail-container-section-heading-para">
              It is industry best practice and our recommendation to remediate
              vulnerabilities identified in this Penetration Testing Report by
              Severity Level.{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="home-main-about-section">
        <div className="home-main-training-section-two">
          <div className="home-main-traning-section-two-img"></div>
        </div>
        <div className="home-main-training-section-one">
          <div className="home-main-about-section-one-heading">
            <h1 className="home-main-about-section-one-heading-text">
              Our Traning Programs
            </h1>
          </div>

          <div className="home-main-about-section-one-text">
            CybrSky Provides hands-on deep depth training to understand the
            cyber security world. Our expertize trainer’s provide basic to
            advance level training and helps to grow in this field. We provide
            corporate environment to learners to get fit in upcoming challenges.
            <br />
            We offers opportunities like full time internship and chance to work
            with us.
            <br /> <br />
            <br />
            <NavLink to="/courses" className="home-aboutus-area-link">
              <button className="home-aboutus-area-link-button">
                Read More
              </button>
            </NavLink>
          </div>
        </div>
      </div>

      <div className="home-quotes-section">
        <div className="home-quotes-section-icon">
        <React.Suspense fallback={<p>Loading...</p>}>
          <RiDoubleQuotesL></RiDoubleQuotesL>
          </React.Suspense>
        </div>
        <div className="home-quotes-section-text">
          “Original and with an innate understanding of their customer&apos;s
          needs, the team at Love Nature are always a pleasure to work with.”
        </div>
      </div>

      <React.Suspense fallback={<p>Loading...</p>}>
      <Footer></Footer>
      </React.Suspense>
    </>
  );
}
export default Home;
