
import { NavLink } from 'react-router-dom';
import { AiOutlineInstagram, AiFillLinkedin,AiOutlineTwitter } from "react-icons/ai";
import { MdAlternateEmail ,MdLocalPhone, MdOutlineLocationOn, MdVerified } from "react-icons/md";


import './footer.css'
export default function Footer()
{
    return(
        <>
                <div className="main-footer-container">
                <div className="main-footer-container-section-one">
                    <div className="main-footer-container-section-one-part1">
                  <span>Let's Get You Secure Digitally</span>  <MdVerified className="MdVerified"></MdVerified>
                    </div>
                </div>
                        <div className="main-footer-container-section-two">
                            
                            
                            <div className="inner-footer-section-space"></div>



                                <div className="inner-footer-section-one">


                                        <div className="inner-footer-container-one">
                                        <div class="inner-footer-container-two-part-one-space"></div>
                                                <div className="inner-footer-container-one-text-wrapper">
                                                    <div className="inner-footer-container-one-contact">

                                                        <div className="inner-footer-container-one-contact-section">
                                                        <MdAlternateEmail className="home-section-contact-icon"></MdAlternateEmail>
                                                        <div className="home-section-contact-text">
                                                        Info@cybrsky.com
                                                        </div>
                                                        
                                                        </div>

                                                        <div className="inner-footer-container-one-contact-section">
                                                        <MdLocalPhone className="home-section-contact-icon"></MdLocalPhone>
                                                        <div className="home-section-contact-text">
                                                        +91 99155 50233
                                                        </div>
                                                        
                                                        </div>

                                                        <div className="inner-footer-container-one-contact-section">
                                                        <MdOutlineLocationOn className="home-section-contact-icon"></MdOutlineLocationOn>
                                                        <div className="home-section-contact-text">
                                                        90D Block A5 Paschim Vihar <br />
                                                         New Delhi 110063 India
                                                        </div>
                                                        
                                                        </div>





                                                    </div>

                                                </div>
                                                <div className="inner-footer-container-one-logo">
                                                </div>
                                        </div>

                                        <div className="inner-footer-container-two">
                                            <div className="inner-footer-container-two-part-one">
                                                <div className="inner-footer-container-two-part-one-space"></div>
                                                        <div className="footer-container-two-part-one-header">
                                                            Navigation
                                                        </div>

                                                        <div className="footer-navlink">
                                                        <NavLink className={({ isActive }) => isActive? "footer-navlink-links": 'footer-navlink-links'}  to="/">Home</NavLink>
                                                        <NavLink className={({ isActive }) => isActive? "footer-navlink-links": 'footer-navlink-links'} to="/about"> About</NavLink>
                                                        <NavLink className={({ isActive }) => isActive? "footer-navlink-links": 'footer-navlink-links'} to="/services">Services</NavLink>
                                                        <NavLink className={({ isActive }) => isActive? "footer-navlink-links": 'footer-navlink-links'} to="/courses">Our Trainings</NavLink>
                                                        <NavLink className={({ isActive }) => isActive? "footer-navlink-links": 'footer-navlink-links'} to="/contact">Contact</NavLink>
                                                       
                                                        </div>
                                            </div>

                                            <div className="inner-footer-container-two-part-one">
                                                <div className="inner-footer-container-two-part-one-space"></div>
                                                        <div className="footer-container-two-part-one-header">
                                                           Services
                                                            </div>
                                                            <div className="footer-services-wrapper">
                                                            <div className="footer-services-wrapper-text">
                                                            Pentest On Demand
                                                            </div>
                                                            <div className="footer-services-wrapper-text">
                                                            Web Application
                                                            </div>
                                                            <div className="footer-services-wrapper-text">
                                                            Mobile Application
                                                            </div>

                                                            <div className="footer-services-wrapper-text">
                                                            Infra & Network

                                                            </div>
                                                            <div className="footer-services-wrapper-text">
                                                            Cloud Security Services

                                                            </div>
                                                            <div className="footer-services-wrapper-text">
                                                            API & End Point Security

                                                            </div>

                                                        </div>
                                            </div>
                                        </div>
                                </div>



                                <div className="inner-footer-section-two">
                                    <div className="inner-footer-section-two-line">
                                        <div className="footer-section-two-line-segment"></div>

                                    </div>
                                    <div className="inner-footer-section-two-media-and-text">
                                    <div className="inner-footer-section-two-companyname">
                                    © 2022 CybrSky Pvt. Ltd.
                                    </div>
                                    <div className="inner-footer-section-two-policy">
                                                <NavLink  to="/privacypolicy" className="inner-footer-section-two-policy-one">Privacy Terms</NavLink>   
                                                <div className="inner-footer-section-two-policy-one">
                                               |
                                                </div>
                                               
                                                <NavLink  to="/cookiepolicy" className="inner-footer-section-two-policy-one">Cookie Policy</NavLink>   
                                           
                                              
                                    </div>
                                        <div className="inner-footer-section-two-media-icons">
                                        <a href="https://twitter.com/SkyCybr?t=CY5gbmfMiSZU2rMaQBA-tA&s=08" className="color-icon" target="_blank" rel="noopener noreferrer">
                                            <AiOutlineTwitter className="media-icons-footer"></AiOutlineTwitter>
                                        </a>
                                        <a href="https://www.linkedin.com/in/cybrsky-944558235" className="color-icon" target="_blank" rel="noopener noreferrer">
                                        <AiFillLinkedin className="media-icons-footer"></AiFillLinkedin>
                                        </a>
                               
                                        <a href="https://www.instagram.com/cybrsky/" className="color-icon" target="_blank" rel="noopener noreferrer">
                                        <AiOutlineInstagram className="media-icons-footer"></AiOutlineInstagram>
                                        </a>

                                     
                                        
                                        </div>
                                    </div>
                                </div>
                        </div>
                </div>
        </>
    );

}
