import React from "react";
import { useState } from "react";
import "./slider.css";
import { sliderdata } from "./carousel-data.js";
import { useEffect } from "react";

const Slider = () => {
  const [Currentslide, setCurrentslide] = useState(0);
  const slidelength = sliderdata.length;

  const autoslide = true;

  const nextslide = () => {
    setCurrentslide(Currentslide === slidelength - 1 ? 0 : Currentslide + 1);
  };
  const slideone = () => {
    setCurrentslide(0);
  };
  const slidetwo = () => {
    setCurrentslide(1);
  };
  const slidethree = () => {
    setCurrentslide(2);
  };

  let intervaltime = 5000;

  let slideinterval;

  function autoslidefun() {
    slideinterval = setInterval(nextslide, intervaltime);
  }

  useEffect(() => {
    setCurrentslide(0);
  }, []);
  useEffect(() => {
    if (autoslide === true) {
      autoslidefun();
      return () => clearInterval(slideinterval);
    }
  });

  return (
    <>
      <div className="div-react-slider">
        {sliderdata.map((slide, index) => {
          return (
            <>
              <div className="main-slider-view-container">
                <div
                  className={
                    index === Currentslide
                      ? "react-current-slider"
                      : "react-slider"
                  }
                  key={index}
                >
                  {index === Currentslide && (
                    <>
                     <div
                        className="img-absolute"
                        style={{ backgroundImage: `url(${slide.image})` }}
                        alt="one"
                      >
                        <div className="main-slider-option-container">
                          <div
                            className="div-slider-button-jump"
                            onClick={slideone}
                          >
                            <span>
                              <h6
                                className={
                                  index === 0
                                    ? "landing-coursel-heading-active"
                                    : "landing-coursel-heading"
                                }
                                key={index}
                              >
                                Comprehensive security
                              </h6>
                            </span>

                            <div className="div-slider-button-jump-timer">
                              <div
                                className={
                                  index === 0
                                    ? "div-slider-button-jump-timer-inner-active"
                                    : "div-slider-button-jump-timer-inner"
                                }
                                key={index}
                              ></div>
                            </div>
                          </div>
                          <div
                            className="div-slider-button-jump"
                            onClick={slidetwo}
                          >
                            <span>
                              <h6
                                className={
                                  index === 1
                                    ? "landing-coursel-heading-active"
                                    : "landing-coursel-heading"
                                }
                                key={index}
                              >
                                AI power
                              </h6>
                            </span>
                            <div className="div-slider-button-jump-timer">
                              <div
                                className={
                                  index === 1
                                    ? "div-slider-button-jump-timer-inner-active"
                                    : "div-slider-button-jump-timer-inner"
                                }
                                key={index}
                              ></div>
                            </div>
                          </div>
                          <div
                            className="div-slider-button-jump"
                            onClick={slidethree}
                          >
                            <span>
                              <h6
                                className={
                                  index === 2
                                    ? "landing-coursel-heading-active"
                                    : "landing-coursel-heading"
                                }
                                key={index}
                              >
                                Secure agile
                              </h6>
                            </span>
                            <div className="div-slider-button-jump-timer">
                              <div
                                className={
                                  index === 2
                                    ? "div-slider-button-jump-timer-inner-active"
                                    : "div-slider-button-jump-timer-inner"
                                }
                                key={index}
                              ></div>
                              </div>
                            </div>
                          </div>
                        </div>
         
                      <h2 className="slide-heading">{slide.heading}</h2>
                      <p className="slide-text">{slide.desc}</p>
                    </>
                  )}
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default Slider;
