import lottie from "lottie-web";
import pentest from './submitforicon.json';
import React, { useRef } from "react";
import './submitlogo.css'

 function Sectionicon()
 {

    const anime = useRef(null);
    setTimeout(() => {
        lottie.loadAnimation({
        container: anime.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData:  pentest,
      })
    }, 1000);

 
    return( <>

<div className="animation-submit-popup">
<div class="center">
<div class="thumb fa fa-thumbs-up"></div>
  <div class="circle-wrap">
    <div class="circle-lg"></div>
  </div>
  <div class="dots-wrap">
    <div class="dot dot--t"></div>
    <div class="dot dot--tr"></div>
    <div class="dot dot--br"></div>
    <div class="dot dot--b"></div>
    <div class="dot dot--bl"></div>
    <div class="dot dot--tl"></div>
  </div>
</div>
</div>
                <div className="pop-up-submit-text-heading">
                Thank you for Contacting us
            </div>
            <div className="pop-up-submit-text">
                We'll get back to you very soon
            </div>
            

    </>);
  };


  
  export default Sectionicon;
